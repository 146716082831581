<template>
  <div class="news-list-box" v-loading="loading">
    <router-link class="return" :to="path"> <<< 返回</router-link>
    <div style="margin: 20px 0;">
      <h2>{{ list.Title }}</h2>
      <div class="time">创建时间：{{ list.CreateDate }}</div>
      <div class="content ql-snow ql-editor" v-html="list.Cont"></div>
    </div>
  </div>
</template>

<script>
// import store from '@/store'
import { getInfoGet, getInfoPost } from "@/api/public";
export default {
  data() {
    return {
      ID: "",
      loading: false,
      list: {}
    };
  },
  computed: {
    path() {
      let path = this.$route.query.path ? this.$route.query.path : "/";
      return path;
    }
  },
  created() {
    this.$store.commit("SET_ADDTITLES", this.$route.query.titles);
    console.log("id", this.$route.query.ID);
    this.ID = this.$route.query.ID;
    this.getList();
  },
  methods: {
    async getList() {
      this.loading = true;
      const r = await getInfoPost({ ID: this.ID }, "/api/DB/SelectOnePublic")
        .catch(e => {
          console.log("e", e);
        })
        .finally(() => {
          this.loading = false;
        });
      if (!r || !r.Data) {
        this.list = {};
        return;
      }
      this.list = r.Data;
    }
  }
};
</script>

<style scoped>
.content ::v-deep img {
  max-width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  text-align: center;
}
.content ::v-deep video {
  width: 700px;
  height: 400px;
}
/* .content ::v-deep iframe{
  width: 700px;height: 400px;
} */
.content ::v-deep iframe.ql-video {
  width: 700px;
  height: 400px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
</style>
<style lang="scss" scoped>
.content ::v-deep p {
  color: #666;
  line-height: 30px;
}
h2 {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  color: #313131;
}
.time {
  width: 100%;
  margin: 0 auto;
  color: #666;
  margin: 15px 0 30px;
  text-align: center;
  padding: 0 0 15px;
  border-bottom: 1px dashed #ccc;
}
.news-list-box {
  position: relative;
}
.return {
  position: absolute;
  top: -60px;
  left: 1px;
}
@media screen and (min-width: 320px) and (max-width: 970px) {
  .return {
    display: none;
  }
  .content {
    padding: 0 10px;
  }
}
</style>
